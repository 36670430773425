import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Clean w/double bounce 4×6\\@65-70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Death by:`}</p>
    <p>{`Minute 1: 5-Double Unders, 1-Power Clean (115/75)(RX+ 135/95)`}</p>
    <p>{`Minute 2: 10-Double Unders, 2-Power Cleans`}</p>
    <p>{`Minute 3: 15-Double Unders, 3-Power Cleans`}</p>
    <p>{`etc.`}</p>
    <p>{`Only completed rounds count.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      